import React from 'react'

import {Link} from 'react-router-dom'

interface CompanyFromProps {
  formType: any
  formik: any
  loading: any
  cancelFile?: any
  showFile?: any
  fileName?: any
}
const CompanyDetailsForm: React.FC<CompanyFromProps> = ({
  formType,
  formik,
  loading,
  cancelFile,
  showFile,
  fileName,
}) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 '
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Organization Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('organizationName')}
                />
                {formik.touched.organizationName && formik.errors.organizationName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.organizationName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 '>Address</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address'
                  {...formik.getFieldProps('org_Address')}
                />
                {formik.touched.org_Address && formik.errors.org_Address && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.org_Address}</div>
                  </div>
                )}
              </div>
            </div>

            {formType === 'Add' && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Admin Name</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Admin Name'
                    {...formik.getFieldProps('adminName')}
                  />
                  {formik.touched.adminName && formik.errors.adminName && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.adminName}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...formik.getFieldProps('org_Email')}
                />
                {formik.touched.org_Email && formik.errors.org_Email && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.org_Email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6 '>Contact No</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Contact No'
                  {...formik.getFieldProps('org_ContactNo')}
                />
                {formik.touched.org_ContactNo && formik.errors.org_ContactNo && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.org_ContactNo}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Monitored Employee Count
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Monitored Employee Count'
                  {...formik.getFieldProps('emp_Strength')}
                />
                {formik.touched.emp_Strength && formik.errors.emp_Strength && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.emp_Strength}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>Tax Number</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Tax Number'
                  {...formik.getFieldProps('TaxNumber')}
                />
                {formik.touched.TaxNumber && formik.errors.TaxNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.TaxNumber}</div>
                  </div>
                )}
              </div>
            </div>

            {formType === 'Add' && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Contract Documents</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-border'
                    placeholder='Payment Receipt'
                    onChange={(e) => {
                      if (e.target.files !== null) {
                        formik.setFieldValue('ContractDocFile', e.target.files[0])
                      }
                    }}
                  />
                  {formik.touched.ContractDocFile && formik.errors.ContractDocFile && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.ContractDocFile}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {formType === 'Edit' && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>Contract Documents</label>

                <div className='col-lg-8 fv-row'>
                  {showFile && (
                    <div className='d-flex'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Contract Document'
                        value={fileName}
                        disabled
                      />
                      <div
                        className='d-flex'
                        style={{
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '1rem',
                        }}
                      >
                        <i
                          className='fa fa-times'
                          onClick={() => cancelFile()}
                          aria-hidden='true'
                          style={{
                            fontSize: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column',

                            cursor: 'pointer',
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                  {!showFile && (
                    <div className='d-flex'>
                      <input
                        type='file'
                        className='form-control form-control-border'
                        placeholder='Payment Receipt'
                        onChange={(e) => {
                          if (e.target.files !== null) {
                            console.log('The file is ', e.target.files[0])
                            const file = e.target.files[0]
                            formik.setFieldValue('ContractDocFile', e.target.files[0])
                            formik.setFieldValue('ContractDocFileName', e.target.files[0].name)
                          }
                        }}
                      />
                    </div>
                  )}

                  {formik.touched.org_ContactNo && formik.errors.org_ContactNo && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.org_ContactNo}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/organization/active'>
              <button type='button' className='btn btn-secondary'>
                Cancel
              </button>
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && (
                <span className='indicator-label'>
                  {formType === 'Add' ? 'Add Organization' : 'Edit Organization'}
                </span>
              )}

              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CompanyDetailsForm
