import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import React, {useEffect, useState} from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {Loader} from '../../../../app/CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../../../../app/pages/Common_Function/Function'
import moment from 'moment'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

dayjs.extend(utc)
dayjs.extend(timezone)
const API = process.env.REACT_APP_API_URL
const Time: React.FC = () => {
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [timedata, setTimeData] = useState<any>([])
  const [screenloader, setScreenLoader] = useState<boolean>(false)

  useEffect(() => {
    let userId = SelectedUser.selectedUserId || localStorage.getItem('userId')
    setTimeData([])
    setScreenLoader(true)
    if (userId) {
      const body = {
        userId: userId,
        organizationId: localStorage.getItem('org_Id'),
      }

      axios
        .post(`${API}/Dashboard/GetUsersWeeklyTimeInSeconds`, body)
        .then((res) => {
          setTimeData(res.data)
          setScreenLoader(false)
        })
        .catch((err) => {
          console.log(err)
          setScreenLoader(false)
        })
    }
  }, [])

  const getDate = (weekDate: string) => {
    const timeZoneOffset = weekDate.substring(19)
    return moment.utc(weekDate).utcOffset(timeZoneOffset).format('MMM DD, YYYY')
  }

  const formattedDate = (dateString: any) => {
    const timeZoneOffset = dateString.dateOfWeek.substring(19)
    const weekDate = moment
      .utc(dateString.dateOfWeek)
      .utcOffset(timeZoneOffset)
      .format('YYYY-MM-DD')
    const currentDate = moment.utc().utcOffset(timeZoneOffset).format('YYYY-MM-DD')
    return weekDate === currentDate ? 'Today' : dateString.day
  }

  return (
    <div className={`card`} style={{width: '49%'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Time</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
        </h3>
        {/* <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            New Member
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle '>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder '>
                <th> </th>
                <th className='min-w-50px'>DAY</th>
                <th className='min-w-200px text-center'>PERCENTAGE(%)</th>
                <th className='min-w-100px text-center'>HOURS</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {timedata.map((item: any, index: any) => (
                <tr key={item.dateOfWeek} style={{lineHeight: '1.3rem'}}>
                  <td></td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                        <span className='text-dark fw-bolder fs-6'>
                          {/* {dayjs().format('YYYY-MM-DD') ===
                          dayjs(item.dateOfWeek).format('YYYY-MM-DD')
                            ? 'Today'
                            : item.day} */}
                          {formattedDate(item)}
                        </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {getDate(item.dateOfWeek)}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      {/* <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>50%</span>
                    </div> */}
                      <div className='progress h-15px w-100' style={{borderRadius: '0px'}}>
                        <Tippy placement='top' content={`Active - ${item.totalActivePercent}%`}>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            style={{
                              width: `${
                                item.totalActivePercent == null ? '0' : item.totalActivePercent
                              }%`,
                              background: '#34A853',
                            }}
                          ></div>
                        </Tippy>

                        <Tippy placement='top' content={`Idle - ${item.totalIdlePercent}%`}>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            style={{
                              width: `${
                                item.totalIdlePercent == null ? '0' : item.totalIdlePercent
                              }%`,
                              background: '#FBBC04',
                              transform: 'translate(-50 %, -50 %)',
                            }}
                          ></div>
                        </Tippy>

                        <Tippy placement='top' content={`Away - ${item.awayTimePercent}%`}>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            style={{
                              width: `${
                                item.awayTimePercent == null ? '0' : item.awayTimePercent
                              }%`,
                              background: '#9D6B50',
                            }}
                          ></div>
                        </Tippy>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center' style={{justifyContent: 'center'}}>
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark fw-bolder fs-6'>
                          {convertSecIntoHoursMinSec(item.totalTime)}
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

              {/* <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        Jessie Clarcson
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        C#, ASP.NET, MS SQL
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                    Agoda
                  </a>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                    Houses &amp; Hotels
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>70%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{ width: '70%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        Lebron Wayde
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        PHP, Laravel, VueJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Transportation</span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>60%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{ width: '60%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-20.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        Natali Goodwin
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        Python, PostgreSQL, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                    The Hill
                  </a>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Insurance</span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{ width: '50%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        Kevin Leonard
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Art Director</span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>90%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-info'
                        role='progressbar'
                        style={{ width: '90%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr> */}
            </tbody>
            {/* end::Table body */}
          </table>

          {screenloader && Loader('100px')}

          {!screenloader && timedata.length === 0 && (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Time}
