import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import swal from 'sweetalert2'
import axios from 'axios'
import Switch from 'react-switch'
import 'tippy.js/dist/tippy.css'
import 'react-rangeslider/lib/index.css'
import 'rc-time-picker/assets/index.css'
import Swal from 'sweetalert2'
import {AlertModal} from '../../CommonFunctions/CommonFunction'
import RowWithSlider from './RowWithSlider'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {companyInfo} from '../../modules/auth/redux/AuthCRUD'
import {getOrganizationNameAndID} from '../Common_Function/Function'
import RangeSlider from 'react-rangeslider'

const API = process.env.REACT_APP_API_URL

const OrgConfig: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [memberOption, setMemberOption] = useState<[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [showDetails, setShowDetails] = useState<boolean>(false)

  const initialValues = {
    orgId: '',
    isEnableLiveRecording: false,
    isEnableLiveStreaming: false,
    isEnableManualTracking: false,
    isEnableScreenshotBlurEffect: false,
    screenCaptureActivityInterval: '',
    idleTimeFromSec: '',
    isSmartIdleEnable: false,
    projectTaskConfig: false,
    isIdlePopUpEnable: false,
  }

  const formik = useFormik({
    initialValues,
    //validationSchema: configschema,
    onSubmit: (values) => {
      setLoading(true)
      AlertModal(
        'Are you sure?',
        'It Will Change Configuration',
        'warning',
        true,
        '#3085d6',
        'Update'
      ).then((result: any) => {
        if (result.isConfirmed) {
          axios
            .post(`${API}/OrganizationAppConfiguration/UpdateOrganizationAppConfig`, values)
            .then((res) => {
              AlertModal(
                res.data.message,
                '',
                res.data.success ? 'success' : 'warning',
                false,
                '#7066E0',
                'Ok'
              )
              callconfigdetail(optionSelectedMember.value)
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
            })
        } else if (result.dismiss === swal.DismissReason.cancel) {
          AlertModal('Nothing Has Been Changed', '', 'error', false, '#7066E0', 'Ok')
          callconfigdetail(optionSelectedMember.value)
          setLoading(false)
        }
      })
    },
  })

  const callconfigdetail = (orgId: string) => {
    axios
      .get(`${API}/OrganizationAppConfiguration/GetIntervalConfiguration`, {
        params: {
          Org_ID: orgId,
        },
      })
      .then((res) => {
        for (const keys in res.data) {
          formik.setFieldValue(`${keys}`, res.data[keys])
        }

        if (Object.entries(res.data).length > 0) setShowDetails(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getorg()
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (Swal.isVisible() && !Swal.getPopup()?.contains(event.target)) {
        Swal.close()
        setLoading(false)
      }
    }

    document?.addEventListener('click', handleOutsideClick)

    return () => {
      document?.removeEventListener('click', handleOutsideClick)
    }
  })

  const getorg = () => {
    companyInfo()
      .then((res) => {
        console.log(res.data)
        const newList = getOrganizationNameAndID(res.data)
        setMemberOption(newList)
        // setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const changeFieldValueHandle = (name: string, value: any) => {
    formik.setFieldValue(name, String(value))
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Configuration</h3>
          </div>

          <div
            className=' d-flex align-items-center card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title={'Search Organization'}
          >
            <Select
              components={makeAnimated()}
              value={optionSelectedMember}
              options={memberOption}
              placeholder='Select Organi...'
              onChange={(item: any) => {
                setOptionSelectedMember(item)
                formik.setFieldValue('orgId', item.value)
                callconfigdetail(item.value)
              }}
              isClearable={false}
              isSearchable={true}
              closeMenuOnScroll={true}
            />
            {/* {memberError && (
                      <span className='text-danger' style={{marginLeft: '1rem'}}>
                        Please Select Member
                      </span>
                    )} */}
          </div>
        </div>

        {showDetails && (
          <div id='kt_account_profile_details' className='collapse show'>
            <form onSubmit={formik.handleSubmit} className='form'>
              <div className='card-body border-top p-9'>
                <RowWithSlider
                  fieldName='screenCaptureActivityInterval'
                  title='Screen Capture Activity Interval (sec)'
                  value={formik.values.screenCaptureActivityInterval}
                  updateValue={changeFieldValueHandle}
                  min={10}
                  max={20}
                />

                {/* <RowWithSlider
                                    fieldName='recordingCaptureDurationMinutes'
                                    title='Recording Capture Duration (min)'
                                    value={formik.values.recordingCaptureDurationMinutes}
                                    updateValue={changeFieldValueHandle}
                                    min={5}
                                    max={120}
                                />

                                <RowWithSlider
                                    fieldName='recordingFramesDurationSeconds'
                                    title='Recording Frames Duration (sec)'
                                    value={formik.values.recordingFramesDurationSeconds}
                                    updateValue={changeFieldValueHandle}
                                    min={0}
                                    max={5}
                                /> */}
                {/* 
                                <RowWithSlider
                                    fieldName='idleTimeFromSec'
                                    title='Idle Time From (sec)'
                                    value={formik.values.idleTimeFromSec}
                                    updateValue={changeFieldValueHandle}
                                    min={5}
                                    max={20}
                                /> */}

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Project Task Config
                  </label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('projectTaskConfig', e)
                      }}
                      checked={formik.values.projectTaskConfig}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Streaming</label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isEnableLiveStreaming', e)
                      }}
                      checked={formik.values.isEnableLiveStreaming}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Recording</label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isEnableLiveRecording', e)
                      }}
                      checked={formik.values.isEnableLiveRecording}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Private Mode</label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isEnableManualTracking', e)
                      }}
                      checked={formik.values.isEnableManualTracking}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Screenshot BlurEffect
                  </label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isEnableScreenshotBlurEffect', e)
                      }}
                      checked={formik.values.isEnableScreenshotBlurEffect}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Smart Idle Enable</label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isSmartIdleEnable', e)
                      }}
                      checked={formik.values.isSmartIdleEnable}
                    />
                  </div>
                </div>

                {formik.values.isSmartIdleEnable && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      Smart Idle Time (sec)
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <RangeSlider
                        value={Number(formik.values.idleTimeFromSec)}
                        onChange={(e: any) => formik.setFieldValue('idleTimeFromSec', e)}
                        min={5}
                        max={20}
                        step={1}
                        labels={{
                          0: '5',
                          [formik.values.idleTimeFromSec]:
                            Number(formik.values.idleTimeFromSec) <= 5
                              ? '5'
                              : formik.values.idleTimeFromSec,
                          100: '20',
                        }}
                      ></RangeSlider>
                    </div>
                  </div>
                )}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Is Idle PopUp Enable
                  </label>
                  <div className='col-lg-8 fv-row d-flex align-items-center'>
                    <Switch
                      onChange={(e) => {
                        formik.setFieldValue('isIdlePopUpEnable', e)
                      }}
                      checked={formik.values.isIdlePopUpEnable}
                    />
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && <span className='indicator-label'>Update</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <style>
        {`
                        
                        .custom-select-class{
                          text-align: center;
                          width: 13rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .card-toolbar > .css-b62m3t-container > div {
                          width:13rem !important;
                        }
                        

                        

                        
                    `}
      </style>
    </>
  )
}

export default OrgConfig
